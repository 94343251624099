export const CountryAbbreviation: { [s: string]: string } = {
    "Türkei": "tr",
    "Italien": "it",
    "Wales": "gb-wls",
    "Schweiz": "ch",
    "Schweden": "se",
    "Finnland": "fi",
    "Deutschland": "de",
    "England": "gb-eng",
    "Dänemark": "dk",
    "Russland": "ru",
    "Österreich": "at",
    "Belgien": "be",
    "Kroatien": "hr",
    "Nordmazedonien": "mk",
    "Niederlande": "nl",
    "Ukraine": "ua",
    "Spanien": "es",
    "Schottland": "gb-sct",
    "Tschechien": "cz",
    "Polen": "pl",
    "Slowakei": "sk",
    "Portugal": "pt",
    "Ungarn": "hu",
    "Frankreich": "fr",
    "Katar": "qa",
    "Ecuador": "ec",
    "Iran": "ir",
    "Senegal": "sn",
    "USA" : "us",
    "Argentinien" : "ar",
    "Saudi Arabien" : "sa",
    "Tunesien": "tn",
    "Mexiko": "mx",
    "Australien": "au",
    "Marokko": "ma",
    "Japan": "jp",
    "Costa Rica": "cr",
    "Kanada" : "ca",
    "Uruguay" : "uy",
    "Ghana" : "gh",
    "Brasilien" : "br",
    "Südkorea" : "kr",
    "Kamerun": "cm",
    "Serbien" : "rs",
    "Albanien" : "al",
    "Slowenien" : "si",
    "Rumänien" : "ro",
    "Georgien" : "ge"
};

export const CountryFIFACodes: { [s: string]: string } = {
    "Türkei": "TUR",
    "Italien": "ITA",
    "Wales": "WAL",
    "Schweiz": "SUI",
    "Schweden": "SWE",
    "Finnland": "FIN",
    "Deutschland": "GER",
    "England": "ENG",
    "Dänemark": "DEN",
    "Russland": "RUS",
    "Österreich": "AUT",
    "Belgien": "BEL",
    "Kroatien": "CRO",
    "Nordmazedonien": "MKD",
    "Niederlande": "NED",
    "Ukraine": "UKR",
    "Spanien": "ESP",
    "Schottland": "SCO",
    "Tschechien": "CZE",
    "Polen": "POL",
    "Slowakei": "SVK",
    "Portugal": "POR",
    "Ungarn": "HUN",
    "Frankreich": "FRA",
    "Katar": "QAT",
    "Ecuador": "ECU",
    "Iran": "IRN",
    "Senegal": "SEN",
    "USA" : "USA",
    "Argentinien" : "ARG",
    "Saudi Arabien" : "KSA",
    "Tunesien": "TUN",
    "Mexiko": "MEX",
    "Australien": "AUS",
    "Marokko": "MAR",
    "Japan": "JPN",
    "Costa Rica": "CRC",
    "Kanada" : "CAN",
    "Uruguay" : "URU",
    "Ghana" : "GHA",
    "Brasilien" : "BRA",
    "Südkorea" : "KOR",
    "Kamerun": "CMR",
    "Serbien" : "SRB",
    "Albanien" : "ALB",
    "Slowenien" : "SVN",
    "Rumänien" : "ROU",
    "Georgien" : "GEO"
};